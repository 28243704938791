import { Container, Flex, Link, Logo, Stack } from '@thirstycamel/ui'
import React from 'react'
import MainHeadNavLogin from '../MainHeadNavLogin'
import MainHeadNavSearch from '../MainHeadNavSearch'

export const MainHeadNav = () => {
  return (
    <Flex height={['64px', '86px']} bg="green.500" align="center">
      <Container px={[3, 5, 6, 8]} size="xl" h="100%">
        <Stack isInline spacing={[4, 4]} align="center" justify="space-between" h="100%">
          <Flex flex="2 0 0" align="center">
            <Link href="/" title="Go to Home Page" display="inline-block">
              <Logo h={['40px', , '48px', '64px']} />
            </Link>
          </Flex>

          <Flex flex="5 1 0" h="100%">
            <Flex flex="3 1 0" h="100%">
              <MainHeadNavSearch />
            </Flex>

            <Flex
              flex={[, , '2 0 0']}
              pl={[2, 0]}
              justifyContent="flex-end"
              align="center"
              h="100%"
            >
              <MainHeadNavLogin />
            </Flex>
          </Flex>
        </Stack>
      </Container>
    </Flex>
  )
}

export default MainHeadNav
