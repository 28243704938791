import React, { FC } from 'react'
import {
  Stack,
  Flex,
  Text,
  Box,
  Heading,
  Link,
  FlexProps,
  PseudoBox,
  Price,
  Image,
} from '@thirstycamel/ui'
import type CartItemEntity from '@ts/core/src/modules/cart/cartitem.entity'

import { useActions, useStore } from '../../store/hooks'
import imageProxy from '../../utils/imageProxy'
import { eventGA4, eventUA } from '../../utils/gtag'

export interface CartPopoutLineProductProps extends FlexProps {
  line: CartItemEntity
}

export const CartPopoutLineProduct: FC<CartPopoutLineProductProps> = ({ line, ...restProps }) => {
  const { changeCartItemQty: changeQty, fetchCart } = useActions(s => s.cart)

  const handleRemove = () => {
    changeQty({
      qty: 0,
      cartItem: line.id,
    }).then(() => {
      fetchCart()

      eventUA('remove_from_cart', {
        event_category: 'ecommerce',
        event_label: 'Product removed from cart',
        items: [
          {
            id: line.product.id,
            name: line.product.productGroup?.name,
            brand: line.product.productGroup?.brand?.name,
            variant: line.product.factorDescription,
            category: line.product.productGroup?.categories?.map(cat => cat.name).join('/'),
            price: line.total / 100,
          },
        ],
      })

      eventGA4('remove_from_cart', {
        currency: 'AUD',
        value: line.total / 100,
        items: [
          {
            item_id: line.product.id,
            item_name: line.product.productGroup?.name,
            item_brand: line.product.productGroup?.brand?.name,
            item_variant: line.product.factorDescription,
            item_category: line.product.productGroup?.categories?.map(cat => cat.name).join('/'),
            price: line.total / 100,
          },
        ],
      })
    })
  }

  if (!line.product) return null

  return (
    <Flex direction="row" justify="space-between" align="center" w="100%" pr={2} {...restProps}>
      <Stack spacing={5} isInline align="center" pr={3} w="100%" overflow="hidden">
        <Link
          href="/product/[brand]/[slug]"
          hrefAs={`/product/${line.product.productGroup.brand?.name.toLowerCase()}/${
            line.product.slug
          }`}
          flexShrink={0}
        >
          <Image
            src={imageProxy(line.product.media?.[0]?.filename, { height: 80 })}
            size="64px"
            objectFit="contain"
          />
        </Link>

        <Stack spacing={1} w="100%" overflow="hidden">
          <Link
            href="/product/[brand]/[slug]"
            hrefAs={`/product/${line.product.productGroup.brand?.name.toLowerCase()}/${
              line.product.slug
            }`}
            _hover={{ color: 'pink.500', textDecoration: 'underline' }}
          >
            <Heading fontSize="xs" isTruncated textTransform="uppercase">
              {line.product.productGroup.brand?.name} {line.product.productGroup.name}
            </Heading>
          </Link>

          <Stack isInline spacing={1} align="center">
            <Text fontSize="xs" color="gray.500" fontWeight="bold">
              Qty: {line.qty}
            </Text>

            <Box as="span" color="black" fontSize="xs">
              &bull;
            </Box>

            <PseudoBox
              as="button"
              color="pink.500"
              fontWeight="medium"
              fontSize="xs"
              _focus={{ outline: 0, textDecoration: 'underline' }}
              _hover={{ textDecoration: 'underline' }}
              onClick={handleRemove}
            >
              Remove
            </PseudoBox>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={0} textAlign="right">
        <Text fontSize="xs" color="gray.500" fontWeight="bold">
          {line.product.factorDescription}
        </Text>

        <Price color="black" fontFamily="heading" fontSize="xl" value={line.total} />
      </Stack>
    </Flex>
  )
}

export default CartPopoutLineProduct
