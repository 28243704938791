import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { humpclub, RequestOptions } from '../utils/backend'

export const useGamesStatus = () => {
  let { isFetching, error, data } = useQuery<boolean, [string, RequestOptions<any>?]>(
    [`/games/status`],
    humpclub
  )

  return { isFetching, error, isActive: data} 
}
