import React, { FC } from 'react'
import {
  Stack,
  Heading,
  Text,
  Box,
  Flex,
  IncrementInput,
  PseudoBox,
  Link,
  FlexProps,
  Price,
  Image,
  useToast,
  Divider,
  Button,
  Icon,
  LinkButton,
} from '@thirstycamel/ui'
import range from 'lodash.range'
import type { CoreProductEntity } from '@ts/core/src/modules/coreproduct/coreproduct.entity'

import imageProxy from '../../utils/imageProxy'
import { useActions, useStore } from '../../store/hooks'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'
import CartLineProduct from '../CartLineProduct'
import CartItemEntity from '@ts/core/src/modules/cart/cartitem.entity'
import CartPopoutLineBundleProduct from '../CartPopoutLineBundleProduct'

export interface CartPopoutLineBundleProps extends FlexProps {
  line: CartItemEntity
}

export const CartPopoutLineBundle: FC<CartPopoutLineBundleProps> = ({ line, ...restProps }) => {
  const toast = useToast()

  const { cart } = useStore(s => s.cart)

  const price = line.specialPricing?.price ?? line.pricing?.price ?? 0

  const { changeCartItemQty, fetchCart } = useActions(s => s.cart)
  const isFetching = useStore(s => s.cart.isFetching)

  const updateQuantity = quantity => {
    changeCartItemQty({
      qty: quantity,
      cartItem: line.id,
    })
      .then(fetchCart)
      .catch(error => {
        toast({
          title: getNiceErrorMessage(error),
          status: 'error',
        })
        fetchCart()
      })
  }

  const handleChangeQuantity = value => {
    if (value !== line.qty) updateQuantity(value)
  }

  const handleRemove = () => {
    updateQuantity(0)
  }

  return (
    <Box bg="gray.50" border="1px" borderColor="gray.200" borderRadius="sm" {...restProps}>
      <Stack isInline align="baseline" p={4} w="100%">
        <Link
          href="/product/[group]/[slug]"
          hrefAs={`/product/${line.product?.productGroup.slug}/${line.product?.slug}`}
          _hover={{ color: 'pink.500', textDecoration: 'underline' }}
          isDisabled={isFetching}
        >
          <Heading fontSize="sm">{line.product.bundle.title}</Heading>
        </Link>

        <Box flexGrow={1} />

        <Price fontFamily="heading" fontSize="xl" value={price} />
      </Stack>

      <Divider h="1px" />

      <Box>
        {line.product.bundle.groups.map((group, index) => {
          const groupProducts = cart.cartItems.filter(
            cartItem =>
              cartItem.productBundleGroup?.id === group.id && cartItem.parent?.id === line.id,
          )

          return (
            <Box key={group.id}>
              {index > 0 && <Divider h="1px" />}

              <Stack isInline px={4} py={2} bg="gray.100" align="center">
                <Text fontSize="sm" flexGrow={1} color="blackAlpha.800" isTruncated>
                  {group.heading}
                </Text>

                <Text fontSize="sm" fontWeight="medium" color="blackAlpha.600">
                  {groupProducts.length}/{group.quantity}
                </Text>
              </Stack>

              <Divider h="1px" />

              <Stack px={4} py={4} spacing={4}>
                {groupProducts.map((product, index) => {
                  return (
                    <Stack spacing={4} key={product.id}>
                      {index > 0 && <Divider h="1px" />}
                      <CartPopoutLineBundleProduct line={product} bundleGroup={group.id} />
                    </Stack>
                  )
                })}

                {groupProducts.length < group.quantity && [
                  groupProducts.length > 0 && <Divider />,
                  <LinkButton
                    href={{ pathname: '/search', query: { bundleGroup: group.id } }}
                    size="sm"
                    variantColor="blue"
                  >
                    Add Product
                  </LinkButton>,
                ]}
              </Stack>
            </Box>
          )
        })}
      </Box>

      <Box px={4}>
        <Divider />
      </Box>

      <Stack isInline justify="space-between" align="center" px={4} py={2}>
        <PseudoBox
          as="button"
          color="pink.500"
          fontWeight="medium"
          fontSize="sm"
          display="inline-flex"
          outline={0}
          _focus={{ textDecoration: 'underline', boxShadow: 'outline' }}
          _hover={{ textDecoration: 'underline' }}
          onClick={!isFetching ? handleRemove : undefined}
          opacity={isFetching ? 0.6 : null}
          cursor={isFetching ? 'not-allowed' : null}
        >
          Remove
        </PseudoBox>

        <Link
          href="/product/[group]/[slug]"
          hrefAs={`/product/${line.product?.productGroup.slug}/${line.product?.slug}`}
          fontSize="sm"
          color="blackAlpha.600"
          alignItems="baseline"
          display="inline-flex"
        >
          Bundle Details
          <Icon name="external-link" ml={1} />
        </Link>
      </Stack>
    </Box>
  )
}

export default CartPopoutLineBundle
