import React, { useRef, memo, useEffect, useState } from 'react'
import {
  Button,
  Text,
  RemoveScroll,
  Box,
  useTheme,
  Image,
  Portal,
  useToast,
  useBackendQuery,
  useButtonStyle,
  Stack,
  Flex,
  Countdown,
  Heading,
} from '@thirstycamel/ui'
import { useAuthHeaders } from '../../hooks/useAuthHeaders'
import PinEntity from '@thirsty-camel/hump-club/src/modules/pin/pin.entity'

const DriveThruPinModal = () => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const { data, isFetching, refetch } = useBackendQuery<PinEntity>([`/pins`, useAuthHeaders()], {
    humpclub: true,
    enabled: false,
  })

  const getPin = async () => {
    await refetch()

    setIsOpen(true)
  }

  const handleExpired = async () => {
    setIsActive(false)
  }

  useEffect(() => {
    if (data) {
      setIsActive(data?.isActive)
    }
  }, [data])

  return (
    <>
      <Button
        variant="outline"
        variantColor="pink"
        flexShrink={0}
        isLoading={isFetching && !isOpen}
        onClick={() => getPin()}
      >
        I'm at a drivethrough
      </Button>

      {isOpen && (
        <Portal>
          <Box
            position="fixed"
            top={0}
            right={0}
            bottom={0}
            left={0}
            onClick={() => setIsOpen(false)}
            zIndex={theme.zIndices.popover - 1}
            bg={'blackAlpha.300'}
            transition={null}
          />

          <Box
            position="fixed"
            left="50%"
            top={'50%'}
            transform={'translate(-50%, -50%)'}
            zIndex={theme.zIndices.popover}
            w="100%"
            // minWidth={[,]}
            maxWidth={['90vw', '32rem']}
            maxHeight={[, '90vh']}
            bg="white"
            boxShadow="lg"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={10}
          >
            <RemoveScroll enabled={isOpen} removeScrollBar={false}>
              <Stack spacing={5} alignItems="center">
                <Heading>Your In Store PIN</Heading>
                <Stack isInline spacing={5}>
                  {data?.pin?.split('').map(digit => {
                    return (
                      <Text
                        color={isActive ? 'pink.500' : 'gray.200'}
                        fontFamily="heading"
                        fontSize="6xl"
                      >
                        {digit}
                      </Text>
                    )
                  })}
                </Stack>
                {isActive && (
                  <Heading fontSize="xs" textTransform="uppercase" color="pink.500">
                    Remaining time to use this PIN in store:
                  </Heading>
                )}
                <Box>
                  <Countdown time={new Date(data?.expiresAt)} onExpired={handleExpired} />
                </Box>

                {!isActive && (
                  <Button
                    variant="outline"
                    variantColor="pink"
                    flexShrink={0}
                    isLoading={isFetching}
                    onClick={() => refetch()}
                  >
                    Get a new PIN
                  </Button>
                )}
                <Text>
                  Give this code to the Thirsty Camel team member to use instead of your phone
                  number.
                </Text>
              </Stack>
            </RemoveScroll>
          </Box>
        </Portal>
      )}
    </>
  )
}

export default memo(DriveThruPinModal)
