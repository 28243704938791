import React from 'react'
import { Box, BoxProps } from '@thirstycamel/ui'
import CategoriesPopoutListItem from '../CategoriesMenuPopoutListItem'

export const CategoriesPopoutListBack = ({
  onClick,
  label,
}: {
  onClick: () => void
  label: string
}) => (
  <>
    <CategoriesPopoutListItem label={label} iconLeft onClick={onClick} />

    <Box h="1px" bg="gray.300" my={2} mx={6} />
  </>
)

export const CategoriesPopoutList = ({ children, ...restProps }: BoxProps & { items?: any[] }) => {
  return (
    <Box
      flex="1 0 0"
      w={['100%', 'auto']}
      h={['100%', 'auto']}
      maxWidth={[, '33.33%']}
      bg="white"
      pt={5}
      pb={16}
      position="relative"
      transition="250ms ease transform"
      {...restProps}
    >
      {children}
    </Box>
  )
}

export default CategoriesPopoutList
