import React, { useState, useEffect } from 'react'
import { AxiosError } from 'axios'
import {
  Stack,
  Flex,
  Text,
  Box,
  Heading,
  Link,
  BoxProps,
  Button,
  TextInput,
  Checkbox,
  Title,
  Icon,
  useForm,
  FormProvider,
  PopoutCloseButton,
  LinkButton,
  usePopoutContext,
  BackendError,
  FormErrorMessage,
} from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import MainHeadNavLoginPrompt from '../MainHeadNavLoginPrompt'
import Input from '../Input'
import FormError from '../FormError'
import { REGEX_MOBILE_NUMBER, REGEX_MOBILE_NUMBER_OR_EMAIL } from '../../utils/regex'
import { isAxiosError } from '../../utils/typeguards'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'
import { useUser } from '../../hooks/useUser'
import { UserLoginDto } from '@thirsty-camel/hump-club/src/modules/auth/dto/UserLoginDto'
import { useRouter } from 'next/router'

export interface LoginPopoutProps extends BoxProps {
  closeButtonRef?: React.RefObject<HTMLElement>
}

export const LoginPopout = ({ closeButtonRef, ...restProps }: LoginPopoutProps) => {
  const form = useForm<UserLoginDto>()
  const router = useRouter()

  const { onClose } = usePopoutContext()
  const { login } = useActions(actions => actions.auth)
  const { error, isFetching } = useStore(store => store.auth)

  const onSubmit = form.handleSubmit(async values => {
    /* Determine whether email or mobile */
    const isMobile = REGEX_MOBILE_NUMBER.test(values.mobileNumber)

    const isSuccessful = await login({
      [isMobile ? 'mobileNumber' : 'email']: values.mobileNumber,
      password: values.password,
    })

    /* Redirect to the offers page */
    if (isSuccessful) {
      router.push('/account/offers', '/account/offers')
    }
  })

  return (
    <Box {...restProps}>
      <Flex h={['64px', '86px']} top={['-64px', '-86px']} right={0} position="absolute">
        <MainHeadNavLoginPrompt bg="green.500" h="100%" />
      </Flex>

      <Box maxWidth="22rem" w="100%" boxShadow="lg">
        <FormProvider {...form}>
          <Stack as="form" spacing={5} px={5} pb={5} pt={4} bg="white" onSubmit={onSubmit}>
            <Flex justify="space-between" align="center">
              <Link href="/login">
                <Title color="pink.500" fontSize="sm">
                  Login
                </Title>
              </Link>

              <PopoutCloseButton color="pink.500" pos="relative" zIndex={5} ref={closeButtonRef} />
            </Flex>

            <Input
              as={TextInput}
              name="mobileNumber"
              type="tel"
              isRequired
              placeholder="MOBILE NUMBER"
              rules={{
                required: 'Please enter your mobile number.',
                pattern: {
                  value: REGEX_MOBILE_NUMBER_OR_EMAIL,
                  message: 'Please enter a valid Australian mobile number.',
                },
              }}
              _placeholder={{ fontFamily: 'heading' }}
            />

            <Input
              as={TextInput}
              name="password"
              type="password"
              isRequired
              placeholder="PASSWORD"
              _placeholder={{ fontFamily: 'heading' }}
            />

            {error && <FormError variant="left-accent" errors={error} />}

            <Stack isInline spacing={10} justify="space-between" align="center">
              {/* <Input as={Checkbox} name="remember">
                <Heading textTransform="uppercase" fontSize="xs" color="blackAlpha.600">
                  Remember me
                </Heading>
              </Input> */}

              <Button type="submit" variantColor="pink" w="100%" isLoading={isFetching}>
                Jump In
              </Button>
            </Stack>

            <Link
              href="/forgot-password"
              fontFamily="heading"
              textTransform="uppercase"
              fontSize="sm"
              color="pink.400"
              onClick={onClose}
            >
              Forgot password?
            </Link>
          </Stack>
        </FormProvider>

        <Stack spacing={6} px={5} py={8} bg="pink.500">
          <Stack spacing={3}>
            <Title textAlign="center" color="white" fontSize="sm">
              Join the club!
            </Title>

            <Stack as="ul" spacing={3} px={4}>
              {[1, 2].map(line => (
                <Stack key={line} as="li" isInline spacing={3}>
                  <Icon name="check_outline" fontSize="xl" color="green.500" mt="2px" />
                  <Text color="white" fontSize="sm" fontWeight="medium">
                    Great offers and discounts on your favourite liquor products
                  </Text>
                </Stack>
              ))}
            </Stack>
          </Stack>

          <Stack isInline justify="space-between" align="center" pl={4}>
            <Title color="white" fontSize="sm">
              + More
            </Title>
            <LinkButton href="/register" variantColor="pink" variant="outline" onClick={onClose}>
              Join for free
            </LinkButton>
          </Stack>
          {/* <Text fontSize="sm" color="white" textAlign="center" fontWeight="bold">
            Start using your membership for your chance to get $5 or $100 off, every 1 in 10 wins!
          </Text>
          <LinkButton variantColor="pink" variant="outline" href="/win" width="100%">
            VIEW LIVE WINNERS
          </LinkButton> */}
        </Stack>
      </Box>
    </Box>
  )
}

export default LoginPopout
