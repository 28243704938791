import React, { useState } from 'react'
import { Box, FlexProps, Flex, Text } from '@thirstycamel/ui'
import Link from 'next/link'
import { useStore } from '../../store/hooks'
import CategoriesPopoutListItem from '../CategoriesMenuPopoutListItem'
import CategoriesPopoutList, { CategoriesPopoutListBack } from '../CategoriesMenuPopoutList'

const prices = [
  { label: 'Under $10', slug: 'under-10' },
  { label: '$10 to $15', slug: '10-15' },
  { label: '$15 to $25', slug: '15-25' },
  { label: '$50 to $100', slug: '50-100' },
  { label: '$100 plus', slug: 'over-100' },
]

const filters = [
  { label: 'Type', id: 'types' },
  // { label: 'Brand', id: 'brands' },
  // { label: 'Price', id: 'prices' },
]

export interface CategoriesMenuProps extends FlexProps {
  onSelect?: () => void
  onBack?: () => void
  showBack?: boolean
}

export const CategoriesMenu = ({
  onSelect,
  showBack,
  onBack,
  ...restProps
}: CategoriesMenuProps) => {
  const [categoryIndex, setCategoryIndex] = useState<number | null>(null)
  const [filter, setFilter] = useState<string | null>(null)

  const handleSelect = () => {
    setCategoryIndex(null)
    setFilter(null)

    onSelect && onSelect()
  }

  const handleBack = () => {
    setCategoryIndex(null)
    setFilter(null)

    onBack && onBack()
  }

  const rawCategoryData = useStore(store => store.navigation.categories)
  const categories = rawCategoryData.map(r => ({
    label: r.name,
    slug: r.slug,
    order: r.order,
    category: r,
    type: 'category',
    types: r.categories
      .filter(c => !c.hideCategory)
      .sort((a, b) => (a.order || 0) - (b.order || 0))
      .map(r => ({ label: r.name, slug: r.slug })),

    prices,
  }))

  const rawListData = useStore(store => store.navigation.productLists)
  let productLists = []
  if (rawListData) {
    // console.log("rawListData:",rawListData?.length);

    productLists = rawListData.map(r => ({
      label: r.title,
      slug: r.slug,
      types: [],
      prices,
      order: r.order,
      type: 'productlist',
    }))
  } else {
    console.log('no product lists data')
  }

  const combinedData = [...categories, ...productLists].sort(
    (a, b) => (a.order != null ? a.order : 999) - (b.order != null ? b.order : 999),
  )

  return (
    <Flex pos="relative" h="100%" {...restProps}>
      <CategoriesPopoutList
        bg="gray.50"
        borderRight="1px"
        borderColor="gray.100"
        zIndex={[1, 3]}
        h="100%"
      >
        {showBack && <CategoriesPopoutListBack label="Back" onClick={handleBack} />}

        {combinedData.map((list, index) => {
          if (list.type === 'productlist') {
            return (
              <Link href="/[group]" as={`/${list.slug}`} passHref>
                <CategoriesPopoutListItem
                  as="a"
                  label={list.label}
                  textTransform="uppercase"
                  onClick={handleSelect}
                />
              </Link>
            )
          }

          if (list.type === 'category') {
            return (
              <CategoriesPopoutListItem
                label={list.label}
                textTransform="uppercase"
                onClick={() => setCategoryIndex(index)}
                isSelected={index === categoryIndex}
              />
            )
          }
        })}

        <Link href="/[group]" as="/bundles" passHref>
          <CategoriesPopoutListItem
            as="a"
            label="Bundles"
            textTransform="uppercase"
            onClick={handleSelect}
          />
        </Link>
      </CategoriesPopoutList>

      <CategoriesPopoutList
        zIndex={2}
        transform={
          categoryIndex == null
            ? ['translateX(100%) translateZ(0)', 'translateX(-100%) translateZ(0)']
            : null
        }
        position={['absolute', 'relative']}
        borderRight="1px"
        borderColor="gray.100"
      >
        {showBack && (
          <CategoriesPopoutListBack label="Categories" onClick={() => setCategoryIndex(null)} />
        )}

        {categoryIndex != null && (
          <>
            <Link
              href="/[group]?specials=true"
              as={`/${combinedData[categoryIndex]?.slug}?specials=true`}
              passHref
            >
              <CategoriesPopoutListItem
                as="a"
                label={`${combinedData[categoryIndex]?.label} Specials`}
                hideIcon
                textTransform="uppercase"
                onClick={handleSelect}
              />
            </Link>

            <Link href="/[group]" as={`/${combinedData[categoryIndex]?.slug}`} passHref>
              <CategoriesPopoutListItem
                as="a"
                label={`All ${combinedData[categoryIndex]?.label}`}
                hideIcon
                textTransform="uppercase"
                onClick={handleSelect}
              />
            </Link>

            <Box h="1px" bg="gray.300" my={2} mx={6} />

            {filters.map(item => (
              <CategoriesPopoutListItem
                label={item.label}
                textTransform="uppercase"
                onClick={() => setFilter(item.id)}
                isSelected={filter === item.id}
              />
            ))}
          </>
        )}
      </CategoriesPopoutList>

      {categoryIndex != null && filter != null && (
        <CategoriesPopoutList
          bg={combinedData[categoryIndex]?.[filter] ? 'gray.50' : null}
          zIndex={[3, 1]}
          transform={
            combinedData[categoryIndex]?.[filter]
              ? null
              : ['translateX(100%) translateZ(0)', 'translateX(-100%) translateZ(0)']
          }
          position={['absolute', 'relative']}
        >
          {showBack && <CategoriesPopoutListBack label="Filters" onClick={() => setFilter(null)} />}

          {combinedData[categoryIndex]?.[filter]?.map(item => {
            let url: string, href: string

            if (filter === 'types') {
              href = '/[group]/[category]'
              url = `/${combinedData[categoryIndex]?.slug}/${item.slug}`
            }

            return (
              <Link
                href={href || `/[group]?${filter}=${item.slug}`}
                as={url || `/${combinedData[categoryIndex]?.slug}?${filter}=${item.slug}`}
                passHref
              >
                <CategoriesPopoutListItem
                  as="a"
                  label={item.label}
                  textTransform="uppercase"
                  onClick={handleSelect}
                />
              </Link>
            )
          })}
        </CategoriesPopoutList>
      )}
    </Flex>
  )
}

export default CategoriesMenu
