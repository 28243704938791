import {
  Box,
  Button,
  Flex,
  Heading,
  IconSkeleton,
  Image,
  Link,
  LinkButton,
  Price,
  Skeleton,
  Stack,
  StackProps,
  Text,
  useToast,
} from '@thirstycamel/ui'
import type { CoreProductEntity } from '@ts/core/src/modules/coreproduct/coreproduct.entity'
import React from 'react'
import { useActions, useStore } from '../../store/hooks'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'
import imageProxy from '../../utils/imageProxy'
import { ProductType } from '@ts/core/src/modules/coreproduct/coreproduct.types'

export const ProductCardSlimPlaceholder = (props: ProductCardSlimProps) => (
  <Flex
    pb={[, 6]}
    px={[, 4]}
    pr={[1, 4]}
    textAlign="center"
    border={[, '2px']}
    borderColor={[, 'gray.100']}
    borderRadius="lg"
    align="center"
    direction={['row', 'column']}
    bg="white"
    {...props}
  >
    <Flex
      h={['5rem', '7rem']}
      w={['5rem', '7rem']}
      justify="center"
      flexShrink={0}
      border={['1px', 0]}
      borderColor={['gray.100']}
      borderRadius="lg"
      mr={[4, 0]}
      mb={[, 4]}
    >
      <IconSkeleton
        name="bottle-outline"
        h={['4rem', '7rem']}
        w={['4rem', '7rem']}
        animation="0.8s linear infinite alternate"
      />
    </Flex>

    <Flex
      direction={['row', 'column']}
      justify={['space-between', 'center']}
      align="center"
      w="100%"
    >
      <Stack spacing={3} align="center" mb={[, 2]}>
        <Stack spacing={[2, 3]} align={['flex-start', 'center']}>
          <Skeleton h="1.5rem" w={['4rem', '5rem']} />
          <Skeleton h="1rem" w={['6rem', '8rem']} />
        </Stack>
      </Stack>

      <Stack spacing={[2, 3]} align="center">
        <Skeleton h={['1.25rem', '1.5rem']} w={['4rem', '5rem']} />
        <Skeleton h="1rem" w="3rem" />
      </Stack>
    </Flex>

    <Skeleton h="2rem" w="100%" maxW="10rem" display={['none', 'block']} mt={5} />
  </Flex>
)

export interface ProductCardSlimProps extends StackProps {
  product?: CoreProductEntity
}

export const ProductCardSlim = ({ product, ...restProps }: ProductCardSlimProps) => {
  const selectedStore = useStore(s => s.store.selectedStore)
  const isCartFetching = useStore(s => s.cart.isFetching)
  const { addProductToCart } = useActions(s => s.cart)
  const toast = useToast()

  const handleAddToCart = () => {
    try {
      addProductToCart({ product, qty: 1 })
    } catch (error) {
      toast({
        title: getNiceErrorMessage(error),
        status: 'error',
      })
    }
  }

  const href = '/product/[brand]/[slug]'
  const hrefAs = `/product/${product.productGroup.brand?.name.toLowerCase()}/${product?.slug}`

  const isBundle = product?.type === ProductType.BUNDLE
  const canAddToCart =
    (product?.pricing?.price || product?.specialPricing?.price) &&
    selectedStore?.isPickupReady &&
    selectedStore?.isDeliveryReady

  return (
    <Flex
      pt={[, , 2]}
      pb={[, , 6]}
      px={[, , 4]}
      spacing={4}
      textAlign={['left', , 'center']}
      direction={['row', , 'column']}
      border={[, , '2px']}
      borderColor={[, , 'gray.100']}
      borderRadius="lg"
      align="center"
      bg="white"
      h="100%"
      {...restProps}
    >
      <Flex direction={['row', , 'column']} align="center" w="100%" flexGrow={1}>
        <Link href={href} hrefAs={hrefAs} mr={[4, , 0]} mb={[, , 2]} flexShrink={0}>
          <Image
            src={imageProxy(product?.media?.[0]?.filename, { height: 160 })}
            height={['5rem', , '7rem']}
            width={['5rem', , '7rem']}
            objectFit="contain"
            border={['1px', , 0]}
            borderColor="gray.200"
            borderRadius="lg"
            p={[2, 0]}
          />
        </Link>

        <Flex
          direction={['row', , 'column']}
          justify="space-between"
          align="center"
          w="100%"
          mr={[4, 0]}
          overflow="hidden"
        >
          <Link href={href} hrefAs={hrefAs} overflow="hidden" display="block" w="100%">
            <Stack spacing={[0, , 1]}>
              <Heading fontSize="sm" textTransform="uppercase" isTruncated>
                {isBundle ? product.bundle?.title : product?.productGroup?.brand?.name}
              </Heading>

              <Text fontSize="sm" isTruncated>
                {isBundle ? product.bundle?.description : product?.productGroup?.name}
              </Text>
            </Stack>
          </Link>

          <Link href={href} hrefAs={hrefAs} ml={[4, , 0]}>
            <Stack spacing={1} textAlign="center">
              {!(product?.pricing?.price || product?.specialPricing?.price) ? (
                <Box /> // Ensure spacing doesn't go awry
              ) : (
                <Text as={Price} fontSize={['lg', , '2xl']} color="pink.500" fontFamily="heading">
                  {product?.specialPricing?.price || product?.pricing?.price}
                </Text>
              )}

              <Text
                fontSize={['sm', , 'sm']}
                fontFamily="heading"
                textTransform="uppercase"
                color="gray.600"
              >
                {isBundle
                  ? product.bundle?.isMultibuy
                    ? product.bundle?.multibuyLabel || 'Multibuy'
                    : 'Bundle'
                  : product?.factorDescription}
              </Text>
            </Stack>
          </Link>
        </Flex>
      </Flex>

      {canAddToCart && !isBundle ? (
        <Button
          onClick={handleAddToCart}
          variantColor="pink"
          px={6}
          isLoading={isCartFetching}
          size="sm"
          display={['none', , 'flex']}
          mt={5}
          flexShrink={0}
        >
          Add to cart
        </Button>
      ) : selectedStore?.externalURL ? (
        <LinkButton
          href={`${selectedStore.externalURL}${
            product?.santExternalID ? `/lines/${product.santExternalID}` : ''
          }`}
          variantColor="pink"
          size="sm"
          isExternal
          w="100%"
          display={['none', , 'flex']}
          mt={5}
          flexShrink={0}
        >
          Shop Now
        </LinkButton>
      ) : (
        <LinkButton
          href={href}
          hrefAs={hrefAs}
          variantColor="pink"
          size="sm"
          w="100%"
          display={['none', , 'flex']}
          mt={5}
          flexShrink={0}
        >
          View
        </LinkButton>
      )}
    </Flex>
  )
}

export default ProductCardSlim
