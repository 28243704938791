import React, { Fragment, useMemo } from 'react'
import {
  Stack,
  Flex,
  Text,
  Box,
  LinkButton,
  Heading,
  Price,
  BoxProps,
  Spinner,
  useRouteChange,
  PopoutCloseButton,
  RemoveScroll,
} from '@thirstycamel/ui'

import CartPopoutLine from '../CartPopoutLine'
import CartPopoutLineProduct from '../CartPopoutLineProduct'
import { useActions, useStore } from '../../store/hooks'
import CartPopoutLineBundle from '../CartPopoutLineBundle'
import { ProductType } from '@ts/core/src/modules/coreproduct/coreproduct.types'
import doesCartHaveIncompleteBundle from '../../utils/doesCartHaveIncompleteBundle'

export interface CartPopoutProps extends BoxProps {
  closeButtonRef?: React.RefObject<HTMLElement>
}

export const CartPopout = ({ closeButtonRef, children, ...restProps }: CartPopoutProps) => {
  const { cart, isFetching, isPopoutOpen } = useStore(store => store.cart)
  const hidePopout = useActions(actions => actions.cart.hidePopout)

  const hasItems = cart?.cartItems?.length

  const handleRouteChange = () => {
    /* Close the popout when the route changes (if it's open) */
    isPopoutOpen && hidePopout()
  }

  useRouteChange(handleRouteChange)

  const hasIncompleteBundle = useMemo(() => doesCartHaveIncompleteBundle(cart), [cart])

  return (
    <RemoveScroll enabled={isPopoutOpen} removeScrollBar={false}>
      <Box {...restProps}>
        <Flex
          h="64px"
          top="-64px"
          left="50%"
          transform="translateX(-50%)"
          position="absolute"
          color="pink.500"
        >
          {children}

          <Box
            position="absolute"
            left="50%"
            bottom={0}
            transform="translateX(-50%)"
            w="60%"
            h="3px"
            bg="green.500"
            userSelect="none"
          />
        </Flex>

        <Flex
          direction="column"
          bg="white"
          px={4}
          pb={6}
          boxShadow="lg"
          width="22rem"
          position="relative"
          maxHeight="70vh"
          overflowY="auto"
        >
          <Flex justify="space-between" pt={2}>
            <Heading fontSize="sm" color="blackAlpha.800" textAlign="center" mt={2} mb={2} ml={2}>
              My cart
            </Heading>

            <PopoutCloseButton ref={closeButtonRef} />
          </Flex>

          {hasItems ? (
            <Stack spacing={4}>
              <Stack spacing={4} borderBottom="2px" borderColor="gray.200" py={2} w="100%">
                <Box position="relative">
                  {cart?.cartItems.map((cartItem, index) => {
                    if (cartItem.parent) return null

                    return (
                      <Fragment key={cartItem.id}>
                        {index > 0 && <Box height="2px" w="100%" bg="gray.200" />}

                        {cartItem.product?.type === ProductType.BUNDLE ? (
                          <CartPopoutLineBundle line={cartItem} my={2} />
                        ) : (
                          <CartPopoutLineProduct line={cartItem} my={4} />
                        )}
                      </Fragment>
                    )
                  })}

                  {isFetching && (
                    <Flex
                      position="absolute"
                      top={0}
                      right={0}
                      bottom={0}
                      left={0}
                      bg="whiteAlpha.600"
                      align="center"
                      justify="center"
                    >
                      <Spinner size="lg" color="pink.500" />
                    </Flex>
                  )}
                </Box>
              </Stack>

              <Stack spacing={2} px={2}>
                <CartPopoutLine label="Subtotal" price={cart?.subtotal} p={0} />

                {cart?.humpClubDiscountTotal > 0 && (
                  <CartPopoutLine
                    label="Hump Club Offers"
                    price={-cart?.humpClubDiscountTotal}
                    withBorderBottom
                  />
                )}

                {cart?.promotionsTotal > 0 && (
                  <CartPopoutLine
                    label="Promotions"
                    price={-cart?.promotionsTotal}
                    withBorderBottom
                  />
                )}

                <CartPopoutLine
                  label={cart?.type === 'TAH' ? 'Shipping' : 'Click & Collect'}
                  price={cart?.shippingTotal}
                  isFree={cart?.shippingTotal === 0}
                  withBorderBottom
                />

                {cart?.shippingPromotionsTotal > 0 && (
                  <CartPopoutLine
                    label="Delivery Promotions"
                    price={cart?.shippingPromotionsTotal}
                    withBorderBottom
                  />
                )}

                <Flex direction="row" justify="space-between" align="baseline" py={2}>
                  <Heading fontSize="sm">TOTAL</Heading>

                  <Stack textAlign="right" spacing={0}>
                    <Price
                      fontFamily="heading"
                      fontSize="lg"
                      fontWeight="bold"
                      value={cart?.total || 0}
                    />

                    <Text color="gray.500" fontSize="xs">
                      (incl. GST)
                    </Text>
                  </Stack>
                </Flex>
              </Stack>

              {hasIncompleteBundle && (
                <Text textAlign="center" color="blackAlpha.600" fontSize="sm">
                  Please ensure there are no incomplete bundles in your cart before checking out.
                </Text>
              )}

              <LinkButton
                href="/checkout"
                variantColor="pink"
                isLoading={isFetching}
                isDisabled={!hasItems || hasIncompleteBundle}
              >
                Checkout
              </LinkButton>

              <LinkButton href="/cart" variant="ghost" variantColor="pink" isDisabled={!hasItems}>
                Review Cart
              </LinkButton>
            </Stack>
          ) : isFetching ? (
            <Spinner size="lg" color="pink.500" mx="auto" my={5} />
          ) : (
            <Text textAlign="center" pb={4} color="blackAlpha.600" fontWeight="medium">
              Your cart is empty.
            </Text>
          )}
        </Flex>
      </Box>
    </RemoveScroll>
  )
}

export default CartPopout
