import React, { forwardRef, useRef } from 'react'
import {
  StackProps,
  Button,
  Popout,
  PopoutTrigger,
  PopoutContent,
  LinkButton,
  useRouteChange,
  RemoveScroll,
  Text,
  Image,
  Flex,
} from '@thirstycamel/ui'
import useMedia from 'use-media'

import { useStore, useActions } from '../../store/hooks'
import LoginPopout from '../LoginPopout'
import MainHeadNavLoginPrompt from '../MainHeadNavLoginPrompt'
import { useUser } from '../../hooks/useUser'
import MainHeadNavLoggedIn from '../MainHeadNavLoggedIn'

export interface MainHeadNavLoginProps extends StackProps {}

export const MainHeadNavLogin = forwardRef(({ id, ...restProps }: MainHeadNavLoginProps, ref) => {
  const isMobile = useMedia({ maxWidth: '30em' })
  const { isLoggedIn } = useUser()

  const closeButtonRef = useRef(null)

  const { isLoginPopoutOpen } = useStore(store => store.auth)
  const { hideLoginPopout, openLoginPopout } = useActions(store => store.auth)

  useRouteChange(() => isLoginPopoutOpen && hideLoginPopout?.())

  const { isCamelCard } = useUser()

  if (isCamelCard) {
    return (
      <LinkButton
        href="/page/[slug]"
        hrefAs="/page/camel-card"
        variant="unstyled"
        h="100%"
        pos="relative"
        zIndex={1}
      >
        <Flex align="center" px={[, , 4]} h={['64px', '86px']}>
          <Image src="/static/images/camel-card.png" size={['64px', '84px', '136px']} mt={2} />
          <Text
            fontFamily="heading2"
            display={['none', , 'block']}
            textAlign="center"
            color="pink.500"
          >
            CAMEL
            <br /> CARD
          </Text>
        </Flex>
      </LinkButton>
    )
  }

  if (isLoggedIn) {
    return (
      <LinkButton href="/account/offers" variant="unstyled" h="100%">
        <MainHeadNavLoggedIn />
      </LinkButton>
    )
  }

  if (isMobile) {
    return (
      <LinkButton href="/login" variant="unstyled" h="100%">
        <MainHeadNavLoginPrompt />
      </LinkButton>
    )
  }

  return (
    <Popout
      placement="bottom-end"
      strategy="fixed"
      // offset={[-8, 0]}
      initialFocusRef={closeButtonRef}
      isOpen={isLoginPopoutOpen}
      onClose={() => hideLoginPopout?.()}
      onOpen={() => openLoginPopout?.()}
    >
      <PopoutTrigger>
        <Button variant="unstyled" h="100%" _hover={{ bg: 'green.400' }}>
          <MainHeadNavLoginPrompt />
        </Button>
      </PopoutTrigger>

      <PopoutContent>
        <RemoveScroll enabled={isLoginPopoutOpen} removeScrollBar={false}>
          <LoginPopout closeButtonRef={closeButtonRef} maxH="80vh" overflowY="auto" />
        </RemoveScroll>
      </PopoutContent>
    </Popout>
  )
})

export default MainHeadNavLogin
