import React from 'react'
import { Box, BoxProps, Container, Title, ContainerProps } from '@thirstycamel/ui'

export interface TitleBannerProps extends Omit<BoxProps, 'title'> {
  containerSize?: ContainerProps['size']
  title?: string | React.ReactNode
}

export const TitleBanner = ({
  containerSize = 'lg',
  title,
  children,
  ...restProps
}: TitleBannerProps) => {
  return (
    <Box
      bg="gray.50"
      textAlign={['left', 'center']}
      borderBottom="1px"
      borderColor="gray.200"
      pt={[10, 12]}
      pb={[10, 12]}
      {...restProps}
    >
      <Container size={containerSize}>
        {title ? (
          <Title as="h1" fontSize="lg">
            {title}
          </Title>
        ) : (
          children
        )}
      </Container>
    </Box>
  )
}

export default TitleBanner
