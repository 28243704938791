import React, { FC } from 'react'
import {
  Stack,
  Flex,
  Text,
  Box,
  Heading,
  Link,
  FlexProps,
  PseudoBox,
  Price,
  Image,
} from '@thirstycamel/ui'
import type CartItemEntity from '@ts/core/src/modules/cart/cartitem.entity'

import { useActions, useStore } from '../../store/hooks'
import imageProxy from '../../utils/imageProxy'
import { eventGA4, eventUA } from '../../utils/gtag'

export interface CartPopoutLineBundleProductProps extends FlexProps {
  line: CartItemEntity
  bundleGroup?: string
}

export const CartPopoutLineBundleProduct: FC<CartPopoutLineBundleProductProps> = ({
  line,
  bundleGroup,
  ...restProps
}) => {
  const { changeCartItemQty: changeQty, fetchCart } = useActions(s => s.cart)

  const handleRemove = () => {
    changeQty({
      qty: 0,
      cartItem: line.id,
    }).then(() => {
      fetchCart()

      eventUA('remove_from_cart', {
        event_category: 'ecommerce',
        event_label: 'Product removed from cart',
        items: [
          {
            id: line.product.id,
            name: line.product.productGroup?.name,
            brand: line.product.productGroup?.brand?.name,
            variant: line.product.factorDescription,
            category: line.product.productGroup?.categories?.map(cat => cat.name).join('/'),
            price: line.total / 100,
          },
        ],
      })
      eventGA4('remove_from_cart', {
        currency: 'AUD',
        value: line.total / 100,
        items: [
          {
            item_id: line.product.id,
            item_name: line.product.productGroup?.name,
            item_brand: line.product.productGroup?.brand?.name,
            item_variant: line.product.factorDescription,
            item_category: line.product.productGroup?.categories?.map(cat => cat.name).join('/'),
            price: line.total / 100,
          },
        ],
      })
    })
  }

  if (!line.product) return null

  let href = '/product/[brand]/[slug]'
  let hrefAs = `/product/${line.product.productGroup.brand?.name.toLowerCase()}/${
    line.product.slug
  }`

  if (bundleGroup) {
    href = `${href}?bundleGroup=${bundleGroup}`
    hrefAs = `${hrefAs}?bundleGroup=${bundleGroup}`
  }

  return (
    <Flex direction="row" justify="space-between" align="center" w="100%" {...restProps}>
      <Stack spacing={3} isInline align="center" pr={3} w="100%" overflow="hidden">
        <Link href={href} hrefAs={hrefAs} flexShrink={0}>
          <Image
            src={imageProxy(line.product.media?.[0]?.filename, { height: 80 })}
            size="48px"
            objectFit="contain"
          />
        </Link>

        <Stack spacing={1} w="100%" overflow="hidden">
          <Link
            href={href}
            hrefAs={hrefAs}
            _hover={{ color: 'pink.500', textDecoration: 'underline' }}
          >
            <Heading fontSize="xs" isTruncated textTransform="uppercase">
              {line.product.productGroup.brand?.name} {line.product.productGroup.name}
            </Heading>
          </Link>

          <Stack isInline spacing={1} align="center">
            <Text fontSize="xs" color="gray.500" fontWeight="bold">
              {line.product.unitSize}mL
            </Text>

            <Box as="span" color="black" fontSize="xs">
              &bull;
            </Box>

            <Text fontSize="xs" color="gray.500" fontWeight="bold">
              {line.product.factorDescription}
            </Text>

            <Box as="span" color="black" fontSize="xs">
              &bull;
            </Box>

            <PseudoBox
              as="button"
              color="pink.500"
              fontWeight="medium"
              fontSize="xs"
              _focus={{ outline: 0, textDecoration: 'underline' }}
              _hover={{ textDecoration: 'underline' }}
              onClick={handleRemove}
            >
              Remove
            </PseudoBox>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  )
}

export default CartPopoutLineBundleProduct
