import React from 'react'

import { Box, BoxProps, LinkButton, Heading } from '@thirstycamel/ui'

export interface PageUnderConstructionProps extends BoxProps {}

export const PageUnderConstruction = ({ ...restProps }: PageUnderConstructionProps) => {
  return (
    <Box textAlign="center" py={10} {...restProps}>
      <Heading mb={6}>Sorry, this page is under construction!</Heading>

      <LinkButton href="/" variantColor="pink">
        Homepage
      </LinkButton>
    </Box>
  )
}

export default PageUnderConstruction
