import React, { forwardRef } from 'react'

import { StackProps, Stack, Icon, Text } from '@thirstycamel/ui'

export interface MainSubNavItemProps extends StackProps {
  label: string
  icon?: string
  iconRight?: string
}

export const MainSubNavItem = forwardRef(
  ({ label, icon, iconRight, ...restProps }: MainSubNavItemProps, ref) => {
    return (
      <Stack isInline spacing={3} align="center" h="100%" ref={ref} {...restProps}>
        {icon && <Icon name={icon} fontSize="2xl" />}
        <Text fontSize={["md", 'md', "md", "lg"]} fontFamily="heading">
          {label}
        </Text>
        {iconRight && <Icon name={iconRight} fontSize="2xl" />}
      </Stack>
    )
  },
)

export default MainSubNavItem
