import React from 'react'
import {
  Flex,
  Text,
  Box,
  BoxProps,
  theme,
  Stack,
  Link,
  Heading,
  LinkButton,
} from '@thirstycamel/ui'

import ProductCardSlim, { ProductCardSlimPlaceholder } from '../ProductCardSlim'
import StoreCardSlim from '../StoreCardSlim'
import StoreEntity from '@ts/core/src/modules/store/store.entity'
import CoreProductEntity from '@ts/core/src/modules/coreproduct/coreproduct.entity'

export interface SearchPopoutProps extends BoxProps {
  closeButtonRef?: React.RefObject<HTMLElement>
  dimensions: any
  stores: StoreEntity[]
  products: CoreProductEntity[]
  searchValue: string
  setSearchValue: (value: string) => void
  numberOfResults: number
  error?: any
  isFetching?: boolean
}

export const SearchPopout = ({
  products,
  stores,
  dimensions,
  searchValue,
  setSearchValue,
  error,
  numberOfResults,
  isFetching,
  ...restProps
}: SearchPopoutProps) => {
  const hasProducts = products?.length > 0
  const hasStores = stores?.length > 0

  return (
    <Box
      px={[4, 8, , 4, 8]}
      py={[4, 6]}
      bg="white"
      position="fixed"
      top={['150px', '86px']}
      left={['50%', , , , dimensions.x - 32]}
      width={['100%', , , , dimensions.width + 48]}
      transform={['translateX(-50%)', , , , 'none']}
      maxWidth="45rem"
      height={['calc(100% - 150px)', 'auto']}
      maxHeight={[, 'calc(100% - 86px)', 'auto']}
      overflowY="auto"
      zIndex={theme.zIndices.popover}
      {...restProps}
    >
      {hasProducts || hasStores ? (
        <Stack spacing={5}>
          <Flex mx={[, , 'auto']} align={[, , 'center']} pos="relative">
            <Heading textAlign={[, , 'center']} isTruncated maxW={['70%', , 'initial']}>
              {numberOfResults} result{numberOfResults !== 1 ? 's' : ''} for '{searchValue}'
            </Heading>

            <Box
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              right={[0, , 'auto']}
              left={[, , 'calc(100% + 0.5rem)']}
            >
              <LinkButton
                href={{ pathname: '/search', query: { search: searchValue } }}
                variant="ghost"
                variantColor="pink"
                px={4}
              >
                View all
              </LinkButton>
            </Box>
          </Flex>

          {hasStores && (
            <Flex direction={['column', 'row']} align="flex-start">
              {stores.map((store, index) => (
                <Link
                  href="/stores/[slug]"
                  hrefAs={`/stores/${store.slug}`}
                  textDecoration="none"
                  flexBasis={['100%', '50%']}
                  mr={[, index === 0 ? 2 : null]}
                  ml={[, index === 1 ? 2 : null]}
                  mb={[4, , 0]}
                  w="100%"
                >
                  <StoreCardSlim store={store} />
                </Link>
              ))}
            </Flex>
          )}

          {hasProducts && (
            <Flex direction={['column', , 'row']} justify="center">
              {products.map((product, index) => (
                <Box
                  width={['100%', , 'calc(100% / 3)']}
                  pl={[, , index > 0 ? 4 : null]}
                  pb={[4, 6, 0]}
                >
                  <ProductCardSlim product={product} />
                </Box>
              ))}
            </Flex>
          )}
        </Stack>
      ) : isFetching ? (
        <Flex direction={['column', 'row']}>
          <ProductCardSlimPlaceholder
            width={['100%', '50%', 'calc(100% / 3)']}
            mr={[, 4]}
            mb={[4, 0]}
          />
          <ProductCardSlimPlaceholder
            width={['100%', '50%', 'calc(100% / 3)']}
            mr={[, 4]}
            mb={[4, 0]}
          />
          <ProductCardSlimPlaceholder width={['100%', '50%', 'calc(100% / 3)']} />
        </Flex>
      ) : error ? (
        <Text textAlign="center" color="red.500">
          There was an error searching for products.
        </Text>
      ) : !searchValue ? (
        <Text textAlign="center">What are you searching for?</Text>
      ) : (
        <Text textAlign="center">No results matching '{searchValue}'.</Text>
      )}
    </Box>
  )
}

export default SearchPopout
