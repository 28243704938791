import React, { forwardRef } from 'react'
import { Flex, FlexProps, Text, Graphic } from '@thirstycamel/ui'

export interface MainHeadNavLoginPromptProps extends FlexProps {}

export const MainHeadNavLoginPrompt = forwardRef(
  ({ id, ...restProps }: MainHeadNavLoginPromptProps, ref) => {
    return (
      <Flex align="center" pl={[, , 4]} h={['64px', '86px']} ref={ref} {...restProps}>
        <Text fontFamily="heading" display={['none', , 'block']}>
          LOGIN or REGISTER
        </Text>
        <Graphic
          name="humpClubLogo"
          size={['64px', '84px', '140px']}
          transform="rotate(10deg)"
          id={id}
          mt={2}
        />
      </Flex>
    )
  },
)

export default MainHeadNavLoginPrompt
