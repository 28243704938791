import React, { useRef } from 'react'
import { Popout, PopoutContent, PopoutTrigger, Badge, Button } from '@thirstycamel/ui'

import { useStore, useActions } from '../../store/hooks'
import CartPopout from '../CartPopout'

export interface MainSubNavCartProps {
  children: React.ReactNode
}

export const MainSubNavCart = ({ children }: MainSubNavCartProps) => {
  const { isPopoutOpen, cart } = useStore(store => store.cart)
  const { openPopout, hidePopout } = useActions(store => store.cart)

  const closeButtonRef = useRef(null)

  const numberOfCartItems =
    cart?.cartItems?.reduce(
      (sum, cartItem) => (sum += cartItem.productBundleGroup ? 0 : cartItem.qty),
      0,
    ) || 0

  return (
    <Popout
      placement="bottom"
      strategy="fixed"
      // offset={[8, 0]}
      isOpen={isPopoutOpen}
      onClose={() => hidePopout()}
      initialFocusRef={closeButtonRef}
      delay={0}
    >
      <PopoutTrigger>
        <Button
          variant="unstyled"
          color="pink.500"
          _hover={{ color: 'pink.600' }}
          onClick={() => openPopout()}
          position="relative"
        >
          {children}

          {numberOfCartItems > 0 && (
            <Badge
              isRound
              variantColor="pink"
              pos="absolute"
              top="calc(50% + 0.4rem)"
              left={8}
              transform="translateY(-50%)"
              boxShadow="md"
              pointerEvents="none"
            >
              {numberOfCartItems}
            </Badge>
          )}
        </Button>
      </PopoutTrigger>

      <PopoutContent>
        <CartPopout closeButtonRef={closeButtonRef} children={children} />
      </PopoutContent>
    </Popout>
  )
}

export default MainSubNavCart
