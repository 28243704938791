// Taken mostly from prisma-labs/graphql-request
import 'isomorphic-unfetch'
import { print } from 'graphql/language/printer'
import { DocumentNode } from 'graphql'

export type Variables = { [key: string]: any }

export interface Headers {
  [key: string]: string
}

export interface Options {
  method?: RequestInit['method']
  headers?: Headers
  mode?: RequestInit['mode']
  credentials?: RequestInit['credentials']
  cache?: RequestInit['cache']
  redirect?: RequestInit['redirect']
  referrer?: RequestInit['referrer']
  referrerPolicy?: RequestInit['referrerPolicy']
  integrity?: RequestInit['integrity']
}

export interface GraphQLError {
  message: string
  locations: { line: number; column: number }[]
  path: string[]
}

export interface GraphQLResponse {
  data?: any
  errors?: GraphQLError[]
  extensions?: any
  status: number
  [key: string]: any
}

export interface GraphQLRequestContext {
  query: string
  variables?: Variables
}

export class ClientError extends Error {
  response: GraphQLResponse
  request: GraphQLRequestContext

  constructor(response: GraphQLResponse, request: GraphQLRequestContext) {
    const message = ClientError.extractMessage(response)
    super(message)
    this.response = response
    this.request = request

    // this is needed as Safari doesn't support .captureStackTrace
    /* tslint:disable-next-line */
    if (typeof (Error as any).captureStackTrace === 'function') {
      ;(Error as any).captureStackTrace(this, ClientError)
    }
  }

  private static extractMessage(response: GraphQLResponse): string {
    try {
      return response.errors![0].message
    } catch (e) {
      return `GraphQL Error (Code: ${response.status})`
    }
  }
}

async function baseFetchGraphQL<T extends any, V extends object>(
  uri: string,
  query: string | DocumentNode,
  variables?: V,
  options: Options = {},
): Promise<T> {
  const { headers, ...others } = options
  const printedQuery = typeof query === 'string' ? query : print(query)
  const body = JSON.stringify({
    query: printedQuery,
    variables: variables ? variables : undefined,
  })

  try {
    const response = await fetch(uri, {
      method: 'POST',
      headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
      body,
      ...others,
    })

    const contentType = response.headers.get('Content-Type')
    const result = await (contentType && contentType.startsWith('application/json')
      ? response.json()
      : response.text())
    if (response.ok && !result.errors && result.data) {
      return result.data
    } else {
      const errorResult = typeof result === 'string' ? { error: result } : result
      throw new ClientError(
        { ...errorResult, status: response.status },
        { query: printedQuery, variables },
      )
    }
  } catch (e) {
    if (e instanceof ClientError) {
      throw e
    } else {
      throw new ClientError({ ...e, status: '400' }, { query: printedQuery, variables })
    }
  }
}

export interface Middleware {
  (fetch: typeof fetchGraphQL): typeof fetchGraphQL
}

export const applyMiddleware = (baseFetch: typeof fetchGraphQL, middlewares: Middleware[]) => {
  return middlewares.reduce((fetch, middleware) => {
    return middleware(fetch)
  }, baseFetch)
}

export async function fetchGraphQL<T extends any, V extends object>(
  uri: string,
  query: string | DocumentNode,
  variables?: V,
  { middleware = [], ...options }: Options & { middleware?: Middleware[] } = {},
): Promise<T> {
  return await applyMiddleware(baseFetchGraphQL, middleware)(uri, query, variables, options)
}
