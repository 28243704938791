import React from 'react'

import {
  Stack,
  StackProps,
  Heading,
  IconButton,
  Card,
  Skeleton,
  Icon,
  Text,
} from '@thirstycamel/ui'
import { StoreEntity } from '@ts/core/src/modules/store/store.entity'
import { getStoreOpenCloseStatus } from '@ts/core/src/shared/services/time'
import { useStore } from '../../store/hooks'

/** TODO: Be more creative when naming components */
const TextAndIcon = ({ text, icon, ...restProps }) => (
  <Stack spacing={1} isInline align="center" {...restProps}>
    <Icon name={icon} fontSize="xs" />
    <Text fontSize="xs" fontWeight="medium">
      {text}
    </Text>
  </Stack>
)

export const StoreCardSlimPlaceholder = (props: StoreCardSlimProps) => (
  <Skeleton>
    <StoreCardSlim store={{}} {...props} />
  </Skeleton>
)

export interface StoreCardSlimProps extends StackProps {
  store?: Partial<StoreEntity>
  icon?: string
  onClickIcon?: () => void
  showOpeningHours?: boolean
  showAddress?: boolean
}

export const StoreCardSlim = ({
  store,
  icon = 'info-outline',
  onClick,
  onClickIcon,
  showOpeningHours = true,
  showAddress = false,
  ...restProps
}: StoreCardSlimProps) => {
  return (
    <Stack
      as={Card}
      isInline
      spacing={4}
      bg="gray.50"
      py={3}
      px={4}
      onClick={onClick}
      flex="1 1 0"
      justify="space-between"
      {...restProps}
    >
      <Stack spacing={1} alignItems={'flex-start'}>
        <Heading fontSize="xs" textTransform="uppercase" color="pink.500">
          {store.name}
        </Heading>
        {showOpeningHours && (
          <Heading fontSize="2xs">{getStoreOpenCloseStatus(store.hours)}</Heading>
        )}
        {showAddress && <Heading fontSize="2xs">{store.address}</Heading>}
        <Stack isInline spacing={3}>
          {store.isDeliveryReady && <TextAndIcon text="Delivery" icon="truck" />}
          {store.isPickupReady && <TextAndIcon text="Pickup" icon="briefcase" />}
          {store.distance && <TextAndIcon text={`${store.distance} km away`} icon="place" />}
        </Stack>
      </Stack>

      <IconButton
        as="div"
        icon={icon}
        onClick={onClickIcon}
        variant="outline"
        variantColor="pink"
        fontSize="lg"
        hasDepth
      />
    </Stack>
  )
}

export default StoreCardSlim
