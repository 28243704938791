import React from 'react'
import { Popout, PopoutContent, PopoutTrigger, Button } from '@thirstycamel/ui'

import MainSubNavItem from '../MainSubNavItem'
import CategoriesPopout from '../CategoriesPopout'

export const MainSubNavCategories = () => {
  const heading = <MainSubNavItem label="PRODUCTS" iconRight="chevron-down" spacing={0} px={[2,2, 2, 4]} />

  return (
    <Popout placement="bottom" strategy="fixed">
      <PopoutTrigger>
        <Button variant="unstyled" h="100%" color="pink.500" _hover={{ color: 'pink.600' }}>
          {heading}
        </Button>
      </PopoutTrigger>

      <PopoutContent>
        <CategoriesPopout heading={heading} />
      </PopoutContent>
    </Popout>
  )
}

export default MainSubNavCategories
