import React, { useRef, memo, useEffect, useState } from 'react'
import {
  Button,
  Logo,
  Flex,
  Link,
  useRouteChange,
  usePrevious,
  RemoveScroll,
  Box,
  useTheme,
  useDimensions,
  Portal,
} from '@thirstycamel/ui'
import useScrollPosition from '@react-hook/window-scroll'

import { useActions, useStore } from '../../store/hooks'
import StorePickerHeader from '../StorePickerHeader'
import StorePicker from '../StorePicker'

const AUTOCLOSE_SCROLL_POSITION = 10

const MainSubNavStorePicker = () => {
  const theme = useTheme()

  const [subtleBackdropColor, setSubtleBackdropColor] = useState('blackAlpha.100')
  const { isPopoutOpen, isPopoutSubtle } = useStore(store => store.store)
  const { hidePopout, openPopout } = useActions(store => store.store)

  const blockPopout = useStore(store => !!store.modal.showing.length)

  const initialFocusRef = useRef(null)

  const wasPopoutOpen = usePrevious(isPopoutOpen)

  const [anchorRef, dimensions] = useDimensions()

  const scrollPosition = useScrollPosition(0.2)

  /* Close the popup if it is open when the page changes. */
  useRouteChange(() => isPopoutOpen && hidePopout())

  /* Close the popout if it's set to subtle and the user has scrolled over the auto-close amount. */
  useEffect(() => {
    if (isPopoutOpen && isPopoutSubtle && scrollPosition > AUTOCLOSE_SCROLL_POSITION) {
      hidePopout()
    }
  }, [scrollPosition])

  useEffect(() => {
    if (isPopoutOpen && !wasPopoutOpen) {
      window?.dispatchEvent(new Event('resize')) // Fix body scroll bar offsetting the popout

      if (!isPopoutSubtle) {
        setTimeout(() => {
          initialFocusRef.current?.focus?.()
        }, 100)
      } else {
        setSubtleBackdropColor('blackAlpha.200')

        setTimeout(() => {
          setSubtleBackdropColor('transparent')
        }, 1500)
      }
    }
  }, [isPopoutOpen])

  return (
    <>
      <Button
        variant="unstyled"
        fontFamily="heading"
        h="100%"
        maxWidth="20rem"
        w="100%"
        textAlign="left"
        px={4}
        mx={-4}
        _hover={{ bg: 'gray.50' }}
        onClick={() => openPopout()}
        ref={anchorRef}
      >
        <StorePickerHeader isTruncated w="100%" />
      </Button>

      {isPopoutOpen && !blockPopout && (
        <Portal>
          <Box
            position="fixed"
            top={0}
            right={0}
            bottom={0}
            left={0}
            onClick={() => hidePopout()}
            zIndex={theme.zIndices.popover - 1}
            bg={isPopoutSubtle ? subtleBackdropColor : 'blackAlpha.300'}
            transition={isPopoutSubtle ? '500ms ease background-color' : null}
          />

          <Box
            position="fixed"
            top={0}
            left={`${dimensions.x > 0 ? dimensions.x : 0}px`}
            zIndex={theme.zIndices.popover}
            w="100%"
            maxWidth={[, '22rem']}
          >
            <Flex h={['64px', '86px']} bg="green.500" pl={[3, 5]} align="center" boxShadow="lg">
              <Link href="/" title="Go to Home Page" onClick={() => hidePopout()}>
                <Logo h={['40px', , '48px', '64px']} />
              </Link>
            </Flex>

            <RemoveScroll enabled={isPopoutOpen && !isPopoutSubtle} removeScrollBar={false}>
              <StorePicker
                initialFocusRef={initialFocusRef}
                bg="white"
                boxShadow="lg"
                position="relative"
                px={[3, 5]}
                overflowY="auto"
                maxHeight="80vh"
              />
            </RemoveScroll>
          </Box>
        </Portal>
      )}
    </>
  )
}

export default memo(MainSubNavStorePicker)
