import React from 'react'
import { ButtonProps, Button, Icon } from '@thirstycamel/ui'

export const CategoriesPopoutListItem = ({
  label,
  hideIcon,
  iconLeft,
  isSelected,
  ...restProps
}: ButtonProps & {
  label: string
  hideIcon?: boolean
  isSelected?: boolean
  iconLeft?: boolean
}) => {
  return (
    <Button
      variant="unstyled"
      justifyContent={iconLeft ? 'flex-start' : 'space-between'}
      textAlign="left"
      fontFamily="heading"
      fontSize="lg"
      color={isSelected ? 'blackAlpha.800' : 'pink.500'}
      pl={iconLeft ? 2 : 8}
      pr={6}
      py={3}
      w="100%"
      whiteSpace="break-spaces"
      lineHeight={1.25}
      _hover={{ color: isSelected ? 'blackAlpha.800' : 'pink.600' }}
      transition="200ms ease color"
      {...restProps}
    >
      {iconLeft && <Icon name="chevron-left" fontSize="2xl" mr={2} />}
      {label}
      {!hideIcon && !iconLeft && <Icon name="chevron-right" fontSize="2xl" ml={2} />}
    </Button>
  )
}

export default CategoriesPopoutListItem
