import React from 'react'
import { LinkButton, LinkButtonProps, Button, ButtonProps, Icon } from '@thirstycamel/ui'

export interface MainSidebarButtonItemProps extends ButtonProps {
  label: string
  iconLeft?: string
  iconRight?: string
}

export const MainSidebarButtonItem = ({
  label,
  onClick,
  iconLeft,
  iconRight,
  ...restProps
}: MainSidebarButtonItemProps) => {
  return (
    <Button
      onClick={onClick}
      variant="unstyled"
      justifyContent={iconRight ? 'space-between' : 'flex-start'}
      textAlign="left"
      fontFamily="heading"
      fontSize="lg"
      color={'pink.500'}
      pl={8}
      pr={6}
      py={3}
      w="100%"
      whiteSpace="break-spaces"
      lineHeight={1.25}
      _hover={{ color: 'pink.600' }}
      transition="200ms ease color"
      textTransform="uppercase"
      {...restProps}
    >
      {iconLeft && <Icon name={iconLeft} fontSize="2xl" mr={3} />}
      {label}
      {iconRight && <Icon name={iconRight} fontSize="2xl" ml={3} />}
    </Button>
  )
}

export interface MainSidebarNavItemProps extends LinkButtonProps {
  label: string
  iconLeft?: string
  iconRight?: string
}

export const MainSidebarNavItem = ({
  label,
  href,
  iconLeft,
  iconRight,
  ...restProps
}: MainSidebarNavItemProps) => {
  return (
    <LinkButton
      href={href}
      variant="unstyled"
      justifyContent={iconRight ? 'space-between' : 'flex-start'}
      textAlign="left"
      fontFamily="heading"
      fontSize="lg"
      color={'pink.500'}
      pl={8}
      pr={6}
      py={3}
      w="100%"
      whiteSpace="break-spaces"
      lineHeight={1.25}
      _hover={{ color: 'pink.600' }}
      transition="200ms ease color"
      textTransform="uppercase"
      {...restProps}
    >
      {iconLeft && <Icon name={iconLeft} fontSize="2xl" mr={3} />}
      {label}
      {iconRight && <Icon name={iconRight} fontSize="2xl" ml={3} />}
    </LinkButton>
  )
}

export default MainSidebarNavItem
