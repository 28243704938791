import React, { useMemo } from 'react'
import { gql } from 'graphql.macro'
import { useQuery } from 'react-query'
import {
  Box,
  BoxProps,
  Container,
  Heading,
  Flex,
  Text,
  useDisclosure,
  Icon,
  Link,
} from '@thirstycamel/ui'

import { fetchGraphQL } from '../../utils/graphql-fetch'
import { useStore } from '../../store/hooks'
import { SearchTagsDocument, SearchTagsQuery } from '../../../generated'

/* This algorithm is not really that random, nor does it work well on larger arrays - it
 * should be fine for this use-case, however. */
const handleSortRandom = () => {
  return 0.5 - Math.random()
}

gql`
  query SearchTags($first: Int, $region: String) {
    searchTags(
      first: $first
      where: { region: $region, orderby: { field: MODIFIED, order: ASC } }
    ) {
      edges {
        node {
          id
          title
          acf {
            searchTerm
          }
        }
      }
    }
  }
`

export interface TrendingKeywordsProps extends BoxProps {}

export const TrendingKeywords = ({ ...restProps }: TrendingKeywordsProps) => {
  const location = useStore(s => s.location)
  const selectedStore = useStore(s => s.store.selectedStore)

  const region = selectedStore?.region || location.region || 'VIC'

  const { isOpen, onToggle } = useDisclosure()

  const { data } = useQuery<SearchTagsQuery>(
    ['api/cms', SearchTagsDocument, { first: 5, region }],
    fetchGraphQL,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 30000,
    },
  )

  const keywords = useMemo(
    () => data?.searchTags?.edges?.sort?.(handleSortRandom),
    data?.searchTags?.edges,
  )

  if (!keywords) return null

  return (
    <Box bg="gray.100" borderTop="1px" borderColor="gray.200" pos="relative" {...restProps}>
      <Container
        display="flex"
        flexDirection={['column', , 'row']}
        alignItems="center"
        justifyContent={['center', , 'flex-start']}
        h={[, , '5rem']}
      >
        <Flex
          as="button"
          px={10}
          pos={[, , 'relative']}
          align="center"
          justify={['center', , 'flex-start']}
          h={['4rem', , '100%']}
          w={['100%', , 'auto']}
          onClick={onToggle}
        >
          <Box
            pos="absolute"
            w="100vw"
            h={['4rem', , 'auto']}
            left={[0, , 'auto']}
            right={0}
            top={0}
            bottom={[, , 0]}
            bg="green.500"
          />

          <Box position="relative">
            <Heading
              textTransform="uppercase"
              textAlign={['center', , 'right']}
              fontSize="xs"
              position="relative"
            >
              # Popular
              <Box display={['inline', , 'block']} mx="0.1rem" />
              Thirsts
            </Heading>

            <Icon
              name={isOpen ? 'chevron-up' : 'chevron-down'}
              color="black"
              display={[, , 'none']}
              position="absolute"
              top="50%"
              left="110%"
              transform="translateY(-50%)"
              fontSize="xl"
            />
          </Box>
        </Flex>

        <Flex
          justify="space-between"
          align="center"
          pl={5}
          py={[5, , 0]}
          flex="1 1 0"
          textAlign="center"
          display={[isOpen ? null : 'none', , 'flex']}
        >
          {keywords.map(({ node }) => {
            const query: any = {}

            if (node.acf.searchTerm?.includes?.(':')) {
              node.acf.searchTerm.split('|').forEach(section => {
                const [key, value] = section?.split?.(':') || []
                if (key && value) query[key] = value
              })
            } else {
              query.search = node.acf.searchTerm || node.title
            }

            return (
              <Link href={{ pathname: '/search', query }} key={node.title}>
                <Text mx={2} my={[3, , 0]}>
                  #{node.title}
                </Text>
              </Link>
            )
          })}
        </Flex>
      </Container>
    </Box>
  )
}

export default TrendingKeywords
