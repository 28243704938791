import React from 'react'
import { Stack, Heading, Text, Flex, FlexProps, Price } from '@thirstycamel/ui'

export interface CartPopoutLineProps extends FlexProps {
  label: string
  subLabel?: string
  price?: number
  isFree?: boolean
  withBorderBottom?: boolean
}

const CartPopoutLine = ({
  label,
  subLabel,
  price,
  isFree,
  withBorderBottom,
  ...restProps
}: CartPopoutLineProps) => {
  return (
    <Flex
      direction="row"
      justify="space-between"
      align="center"
      pb={4}
      borderBottom={withBorderBottom ? '2px' : null}
      borderColor={withBorderBottom ? 'gray.200' : null}
      {...restProps}
    >
      <Stack>
        <Heading fontSize="xs" color="gray.500" textTransform="uppercase">
          {label}
        </Heading>

        {subLabel && (
          <Text fontSize="xs" color="gray.500">
            {subLabel}
          </Text>
        )}
      </Stack>

      {isFree ? (
        <Text fontWeight="bold" color="gray.500" fontFamily="heading">
          FREE
        </Text>
      ) : (
        <Price
          fontFamily="heading"
          fontSize="lg"
          fontWeight="bold"
          color="gray.500"
          value={price}
        />
      )}
    </Flex>
  )
}

export default CartPopoutLine
