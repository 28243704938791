import React from 'react'
import { Box, BoxProps, Flex, RemoveScroll, usePopoutContext } from '@thirstycamel/ui'

import CategoriesMenu from '../CategoriesMenu'

export interface CategoriesPopoutProps extends BoxProps {
  heading: React.ReactNode
}

export const CategoriesPopout = ({ heading, ...restProps }: CategoriesPopoutProps) => {
  const popout = usePopoutContext()

  const handleSelect = () => popout.onClose()

  return (
    <Box {...restProps}>
      <Box
        h="64px"
        top="-64px"
        left="50%"
        transform="translate(-50%)"
        position="absolute"
        boxShadow="lg"
      >
        <Box bg="white" h="100%">
          {heading}
        </Box>

        <Box
          position="absolute"
          left="50%"
          bottom={0}
          transform="translateX(-50%)"
          w="60%"
          h="3px"
          bg="green.500"
          userSelect="none"
        />
      </Box>

      <RemoveScroll enabled={popout.isOpen} removeScrollBar={false}>
        <Flex
          bg="white"
          boxShadow="lg"
          maxWidth="42rem"
          minHeight="15rem"
          position="relative"
          overflowY="auto"
          maxHeight="60vh"
        >
          <CategoriesMenu onSelect={handleSelect} />
        </Flex>
      </RemoveScroll>
    </Box>
  )
}

export default CategoriesPopout
