import React, { forwardRef } from 'react'
import { Flex, FlexProps, Text, Graphic, Stack } from '@thirstycamel/ui'
import { useUser } from '../../hooks/useUser'

export interface MainHeadNavLoggedInProps extends FlexProps {}

export const MainHeadNavLoggedIn = forwardRef(
  ({ id, ...restProps }: MainHeadNavLoggedInProps, ref) => {
    const { user, isHumpClubAvailable } = useUser()

    return (
      <Flex align="center" pl={[, , 4]} h={['64px', '86px']} ref={ref} {...restProps}>
        <Stack spacing={0} display={['none', , 'block']}>
          {isHumpClubAvailable ? (
            <>
              <Text fontWeight="medium">Hi, {user?.firstName}</Text>
              <Text fontWeight="bold" fontFamily="heading" color="pink.500">
                {user?.displayLevel}
              </Text>
            </>
          ) : (
            <Text fontWeight="medium">
              Hi,{' '}
              <Text as="span" fontWeight="bold">
                {user?.firstName}
              </Text>
            </Text>
          )}
        </Stack>
        <Graphic
          name="humpClubLogo"
          size={['64px', '84px', '140px']}
          transform="rotate(10deg)"
          id={id}
          mt={2}
        />
      </Flex>
    )
  },
)

export default MainHeadNavLoggedIn
